import React from 'react'

export default function Banner() {
  return (
    <div className="bg-[#371101] bg-[url('assets/pinnacleAgile/banner/mob-original.jpg')] min-[500px]:bg-[url(assets/pinnacleAgile/banner/desk.jpg)] h-[650px] bg-[60%_50%] min-[500px]:bg-center md:bg-[url(assets/pinnacleAgile/banner/desk.jpg)] md:h-[115vh] md:min-h-[700px] bg-cover md:bg-center xl:bg-bottom lg:min-h-[750px] xl:min-h-[800px] 2xl:bg-center bg-no-repeat">
      <div className="flex items-end blade-bottom-padding lg:blade-bottom-padding-sm h-full">
        <div className="flex flex-col md:flex-row w-container md:items-center md:justify-center gap-y-4">
          <div className="w-fit">
            {/* <div className="basis-[20rem] lg:basis-[20rem] xl:basis-[20rem] 2xl:basis-[22rem] flex-col grow-0 shrink flex  items-start justify-center gap-5"> */}
            <div className="h-12 md:h-14 lg:h-16 p-1 ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-full w-auto 2xl:h-20"
                viewBox="0 0 155 80"
                fill="none"
              >
                <path
                  d="M71.3781 54.9485L70.6797 69.1965H73.4734V53.9707H72.4956C71.9368 53.9707 71.5178 54.3898 71.3781 54.9485Z"
                  fill="white"
                />
                <path
                  d="M78.6414 66.9615V53.9707H77.5239C76.9652 53.9707 76.5461 54.3898 76.5461 54.8088L75.8477 69.0568H83.1113V66.9615H78.6414Z"
                  fill="white"
                />
                <path
                  d="M92.889 56.066V53.9707H86.184C85.6253 53.9707 85.2062 54.3898 85.2062 54.8088L84.5078 69.0568H93.0287V66.9615H87.4412V62.4915H92.3302V60.3962H87.4412V55.9263H92.889V56.066Z"
                  fill="white"
                />
                <path
                  d="M54.4774 53.9707H53.4996C53.0805 53.9707 52.6615 54.2501 52.5218 54.6691L47.6328 69.0568H50.4265L53.2202 58.301L55.8743 69.0568H58.668L54.4774 53.9707Z"
                  fill="white"
                />
                <path
                  d="M65.5134 58.301H68.4468V57.4628C68.3071 55.9263 67.3293 53.9707 64.3959 53.9707C60.345 53.9707 59.9259 56.6247 59.9259 57.6025L59.3672 65.4249C59.3672 66.1234 59.3672 66.6821 59.6465 67.1012C59.9259 67.5202 60.2053 67.9393 60.6244 68.2187C61.0434 68.498 61.3228 68.6377 61.8815 68.7774C62.0212 68.7774 62.0212 68.7774 62.1609 68.7774C62.7196 68.9171 63.2784 68.9171 63.8371 68.9171C64.5355 68.9171 65.0943 68.7774 65.653 68.6377C66.2118 68.498 66.6308 68.2187 67.0499 67.7996C67.469 67.5202 67.7483 67.1012 68.0277 66.6821C68.3071 66.263 68.3071 65.844 68.3071 65.4249V60.8153H65.7927H65.5134H63.6975V63.0503H65.5134V65.2852C65.5134 65.5646 65.5133 65.844 65.3737 65.9837C65.234 66.1234 65.0943 66.4027 64.9546 66.4027C64.8149 66.5424 64.5356 66.5424 64.3959 66.6821C64.1165 66.6821 63.9768 66.8218 63.8371 66.8218C63.5577 66.8218 63.2784 66.8218 62.999 66.6821C62.7197 66.5424 62.5799 66.5424 62.4402 66.4027C62.1609 66.1234 62.0212 65.7043 62.0212 65.2852L62.1609 57.6025C62.1609 57.0438 62.3006 56.6247 62.4402 56.3454C62.7196 56.066 63.1387 55.7866 63.8371 55.7866C65.5133 55.7866 65.3737 57.3232 65.3737 57.3232V58.301H65.5134Z"
                  fill="white"
                />
                <path
                  d="M62.717 34.6936L61.8789 51.4559H65.2314V33.7158H63.8345C63.2758 33.7158 62.717 34.1349 62.717 34.6936Z"
                  fill="white"
                />
                <path
                  d="M77.3871 33.7158L76.9681 44.4716L71.9394 33.7158H70.6822C69.9838 33.7158 69.5647 34.1349 69.5647 34.6936L68.7266 51.4559H72.0791V40.7001L77.2474 51.4559H80.0411V33.7158H77.3871Z"
                  fill="white"
                />
                <path
                  d="M92.0551 33.7158L91.636 44.4716L86.6073 33.7158H85.3502C84.6517 33.7158 84.2327 34.1349 84.2327 34.6936L83.3945 51.4559H86.747V40.7001L91.9154 51.4559H94.8488V33.7158H92.0551Z"
                  fill="white"
                />
                <path
                  d="M104.763 33.7158H103.645C103.087 33.7158 102.668 33.9952 102.528 34.4142L96.8008 51.4559H100.153L103.506 38.7445L106.718 51.4559H110.071L104.763 33.7158Z"
                  fill="white"
                />
                <path
                  d="M126.837 48.9416V33.7158H125.44C124.742 33.7158 124.322 34.1349 124.322 34.6936L123.484 51.4559H132.005V48.9416H126.837Z"
                  fill="white"
                />
                <path
                  d="M143.038 36.0905V33.7158H135.076C134.377 33.7158 133.958 34.1349 133.819 34.6936L132.98 51.4559H142.898V48.9416H136.333V43.7732H142.06V41.3985H136.333V36.2302H143.038V36.0905Z"
                  fill="white"
                />
                <path
                  d="M121.246 38.7445V37.7667C121.106 35.9508 119.849 33.7158 116.497 33.7158C111.747 33.7158 111.328 36.7889 111.328 37.9064L110.77 47.1257C110.77 47.9638 110.909 48.6622 111.189 49.2209C111.468 49.7797 111.887 50.1987 112.446 50.6178C112.865 50.8972 113.284 51.1765 113.843 51.3162C113.982 51.3162 113.982 51.3162 114.122 51.4559C114.82 51.5956 115.379 51.5956 116.078 51.5956C116.776 51.5956 117.474 51.4559 118.173 51.3162C118.871 51.0369 119.43 50.7575 119.849 50.3384C120.268 49.9194 120.687 49.5003 120.967 49.0813C121.246 48.5225 121.386 48.1035 121.386 47.5447V46.4272H118.033V47.2653C118.033 47.5447 118.033 47.9638 117.894 48.1035C117.754 48.3828 117.614 48.5225 117.335 48.6622C117.055 48.8019 116.916 48.9416 116.636 48.9416C116.357 48.9416 116.217 49.0813 115.938 49.0813C115.519 49.0813 115.239 49.0813 114.96 48.9416C114.681 48.8019 114.541 48.6622 114.262 48.5225C113.982 48.2431 113.843 47.6844 113.843 47.2653L114.122 38.1858C114.122 37.627 114.262 37.0683 114.541 36.6492C114.82 36.2302 115.379 36.0905 116.217 36.0905C118.173 36.0905 118.033 37.9064 118.033 37.9064V38.7445H121.246Z"
                  fill="white"
                />
                <path
                  d="M59.7841 36.5096C59.5047 35.9508 59.2254 35.3921 58.6666 34.8333C58.1079 34.4143 57.4095 33.9952 56.711 33.8555C56.0126 33.7159 55.1745 33.5762 54.3364 33.5762H51.2633C50.5648 33.5762 50.1458 33.9952 50.0061 34.554L49.168 51.3163H52.5205V44.332H54.1967C55.4538 44.332 56.4317 44.1923 57.2698 43.9129C57.9682 43.6335 58.6666 43.0748 59.0857 42.5161C59.5047 42.097 59.7841 41.5383 59.9238 40.9795C60.0635 40.4208 60.0635 39.7223 60.0635 39.0239C60.2032 38.0461 60.0635 37.208 59.7841 36.5096ZM56.9904 40.2811C56.9904 40.7001 56.8507 40.9795 56.711 41.2589C56.5713 41.5383 56.292 41.6779 55.8729 41.8176C55.4539 41.9573 55.0348 42.097 54.3364 42.097H52.6601V36.0905H54.3364C55.0348 36.0905 55.4539 36.2302 55.8729 36.3699C56.292 36.5096 56.4317 36.7889 56.711 37.0683C56.8507 37.3477 56.9904 37.6271 56.9904 38.0461C56.9904 38.4652 56.9904 38.8842 56.9904 39.3033C56.9904 39.5827 56.9904 40.0017 56.9904 40.2811Z"
                  fill="white"
                />
                <path
                  d="M59.7841 36.5096C59.5047 35.9508 59.2254 35.3921 58.6666 34.8333C58.1079 34.4143 57.4095 33.9952 56.711 33.8555C56.0126 33.7159 55.1745 33.5762 54.3364 33.5762H51.2633C50.5648 33.5762 50.1458 33.9952 50.0061 34.554L49.168 51.3163H52.5205V44.332H54.1967C55.4538 44.332 56.4317 44.1923 57.2698 43.9129C57.9682 43.6335 58.6666 43.0748 59.0857 42.5161C59.5047 42.097 59.7841 41.5383 59.9238 40.9795C60.0635 40.4208 60.0635 39.7223 60.0635 39.0239C60.2032 38.0461 60.0635 37.208 59.7841 36.5096ZM56.9904 40.2811C56.9904 40.7001 56.8507 40.9795 56.711 41.2589C56.5713 41.5383 56.292 41.6779 55.8729 41.8176C55.4539 41.9573 55.0348 42.097 54.3364 42.097H52.6601V36.0905H54.3364C55.0348 36.0905 55.4539 36.2302 55.8729 36.3699C56.292 36.5096 56.4317 36.7889 56.711 37.0683C56.8507 37.3477 56.9904 37.6271 56.9904 38.0461C56.9904 38.4652 56.9904 38.8842 56.9904 39.3033C56.9904 39.5827 56.9904 40.0017 56.9904 40.2811Z"
                  fill="white"
                  stroke="#1C75BC"
                  strokeWidth="0.695635"
                  strokeMiterlimit="10"
                />
                <path
                  d="M86.3258 27.57C82.9733 26.4526 70.8207 24.0779 56.7124 24.0779C50.9853 24.0779 45.6772 24.3573 40.5089 24.7763C49.7281 11.7855 58.8077 11.5062 61.4617 11.3665C62.1602 11.3665 62.7189 10.8077 62.7189 10.1093C62.7189 9.41089 62.2998 8.85215 61.6014 8.85215C59.5061 8.43309 46.9344 8.29341 36.5976 25.1954C14.5273 27.57 0 33.4368 0 39.3036C0 45.3101 10.4764 49.6404 28.9149 51.0373C28.9149 61.7931 32.9658 71.2917 43.7216 71.2917L43.8613 69.1964C34.6421 69.1964 31.1499 60.9549 31.4293 51.1769C35.4802 51.4563 39.9501 51.4563 44.6994 51.4563L44.8391 48.2435C42.4645 48.2435 37.4358 48.3832 31.569 48.1039C32.2674 40.9799 34.7817 33.2972 38.2739 27.8494C38.5533 27.2907 38.9723 26.8716 39.2517 26.4526C44.9788 25.8938 50.9853 25.6144 56.9918 25.6144C72.9159 25.6144 84.5099 28.6875 85.9067 29.1066C87.4433 29.5256 87.8623 29.386 88.002 28.9669C88.1417 28.4082 87.8623 28.1288 86.3258 27.57ZM33.6643 30.6431C31.2896 35.6718 29.6134 41.9577 29.0546 47.9642C16.6226 47.2657 2.09529 45.3101 2.09529 39.3036C2.09529 33.8559 16.7623 28.9669 35.6199 26.7319C35.0611 27.9891 34.3627 29.2463 33.6643 30.6431Z"
                  fill="white"
                />
                <path
                  d="M150.023 27.9888H149.324V24.0776H150.302C150.581 24.0776 150.861 24.0776 151 24.0776C151.14 24.0776 151.28 24.0776 151.42 24.2173C151.699 24.357 151.839 24.4967 151.978 24.6364C152.118 24.7761 152.118 25.0554 152.118 25.3348C152.118 25.6142 151.978 25.8936 151.839 26.0332C151.699 26.1729 151.419 26.3126 151.14 26.4523L152.118 27.9888H151.28L150.302 26.1729V27.9888H150.023ZM150.162 26.0332C150.442 26.0332 150.721 26.0332 150.861 25.8936C151 25.7539 151.14 25.6142 151.14 25.4745C151.14 25.3348 151.14 25.0554 151 25.0554C150.861 24.9158 150.721 24.9158 150.442 24.9158H150.162V26.0332Z"
                  fill="white"
                />
                <path
                  d="M150.583 22.1223C148.348 22.1223 146.672 23.9382 146.672 26.0335C146.672 28.2685 148.488 29.9447 150.583 29.9447C152.818 29.9447 154.494 28.1288 154.494 26.0335C154.634 23.7985 152.818 22.1223 150.583 22.1223ZM150.583 29.386C148.767 29.386 147.231 27.8494 147.231 26.0335C147.231 24.2176 148.767 22.681 150.583 22.681C152.399 22.681 153.936 24.2176 153.936 26.0335C153.936 27.8494 152.539 29.386 150.583 29.386Z"
                  fill="white"
                />
              </svg>
            </div>
            <h3
              style={{
                backgroundImage:
                  'linear-gradient(186deg, #FFF -96.64%, #F69A4D 109.37%)',
              }}
              className="bg-clip-text text-transparent font-medium whitespace-nowrap pr-8 hidde lg:block"
            >
              Pinnacle Agile
            </h3>
          </div>
          <div className="w-[5px] mr-[3rem] h-auto self-stretch hidden md:block">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              className="h-full w-auto object-contain"
              viewBox="0 0 8 126"
              fill="none"
            >
              <g filter="url(#filter0_f_1099_14363)">
                <rect
                  x="2"
                  y="2.72168"
                  width="4"
                  height="121"
                  fill="url(#paint0_linear_1099_14363)"
                />
              </g>
              <defs>
                <filter
                  id="filter0_f_1099_14363"
                  x="0"
                  y="0.72168"
                  width="8"
                  height="125"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  />
                  <feGaussianBlur
                    stdDeviation="1"
                    result="effect1_foregroundBlur_1099_14363"
                  />
                </filter>
                <linearGradient
                  id="paint0_linear_1099_14363"
                  x1="4"
                  y1="2.72168"
                  x2="41.2978"
                  y2="8.69574"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FFE9D7" />
                  <stop offset="1" stopColor="#F69A4D" />
                </linearGradient>
              </defs>
            </svg>
          </div>

          <div className="flex text-white flex-col gap-3 items-start w-fit ">
            <h5 className="font-regular tracking-wide text-white leading-snug lg:max-w-sm md:hidden w-10/12 ">
              World's{' '}
              <strong className="font-medium tracking-wider">smallest</strong>{' '}
              fixed flat-panel Cath Lab
            </h5>
            <h4 className="font-regular hidden md:block tracking-wide text-white leading-snug max-w-[18rem] lg:max-w-[22rem] ">
              World's{' '}
              <strong className="font-medium tracking-wider">smallest</strong>{' '}
              fixed flat-panel Cath Lab
            </h4>
            <ul className="  pl-4 md:pl-0 md:list-none list-disc flex gap-x-4 md:divide-x-1 divide-white text-[14px] md:text-[16px] 2xl:text-[18px]  font-regular font-normal md:flex-row flex-col gap-y-1 ">
              <li>65 kW generator</li>
              <li className="md:pl-4">1.5 MHU X-ray tube</li>
              <li className="md:pl-4">Real-time image acquisition</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
