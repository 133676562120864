import React, { ReactNode } from 'react'
import { TextAnchor } from 'atoms/links'
import logoSVG from 'assets/globals/logo_svg.svg'
import { Disclosure, Transition } from '@headlessui/react'
import CathLabRoutes from 'molecules/cathLabRoutes'
import StentRoutes from 'molecules/stentRoutes'
import BalloonRoutes from 'molecules/balloonRoutes'
import OtherRoutes from 'molecules/otherRoutes'
import SocialProfile from 'molecules/socialProfile'
import ScrollToTopBtn from 'molecules/ScrollToTopBtn'

export default function Footer({
  toggle,
}: {
  toggle: React.DispatchWithoutAction
}) {
  return (
    <footer>
      <section className="font-regular">
        <section className="flex flex-col lg:grid xsl:gap-y-10 grid-cols-1 md:grid-cols-12 lg:divide-x-1 divide-solid divide-gray border-t-1 border-solid border-gray ">
          {/* Addess section (left in desk) */}
          <section className=" col-start-1 col-end-2 md:col-end-4 lg:col-start-1 lg:col-end-4 lg:px-8 blade-top-padding lg:blade-top-padding-sm w-11/12 mx-auto lg:w-full lg:mx-0  xsl:px-10 xsl:col-start-2 xsl:col-end-5 xsl:pl-0">
            <div className="grid min-[500px]:grid-cols-2 gap-x-10 gap-y-6 lg:grid-cols-1 lg:gap-y-10 2xl:w-10/12">
              <div>
                <Contact
                  title="Contact"
                  text="+91 6364882807"
                  linkTo="tel:+91 6364882807"
                />
              </div>
              <div>
                <Contact
                  title="E-mail"
                  text="lavanyam@innvolution.com"
                  linkTo="mailto:lavanyam@innvolution.com"
                />
              </div>
              <div className="min-[500px]:col-span-2 lg:col-span-1">
                <Title title="Address" />

                <ul className="font-regular max-w-md">
                  <li className="text-sm lg:text-base">
                    121 F, Bommasandra Industrial Area,{' '}
                    <span className="whitespace-nowrap">Phase 1</span>,
                    Hulimangla Road, Hosur Main road, Electronic City, Banglore
                    560 099, Karnataka, India
                  </li>
                </ul>
              </div>
            </div>
          </section>

          {/* Company, resources and products section */}
          <section className="pt-6 h-min  lg:blade-top-padding-sm blade-bottom-padding md:blade-bottom-padding-sm grid gap-y-6 sm:grid-cols-6 sm:gap-x-10 lg:mx-0 xsl:col-start-5 xsl:col-end-12 xsl:pr-0  lg:col-start-4 lg:col-end-13 lg:gap-x-16 lg:gap-y-10 lg:px-8 xsl:px-10 2xl:px-20 mx-auto w-11/12 lg:w-full">
            {/* Company part */}
            <div className="sm:col-span-3 ">
              <Title title="Company" />

              <div className="grid grid-cols-2 gap-y-2 gap-x-6">
                <TextAnchor
                  className="hover:text-orange"
                  href="/who-we-are"
                  text="Who we are"
                />
                <TextAnchor
                  className="hover:text-orange"
                  href="/innovation"
                  text="Innovation "
                />
                <TextAnchor
                  className="hover:text-orange"
                  href="/services-and-support"
                  text="Services & Support"
                />
                <TextAnchor
                  className="hover:text-orange"
                  href="/contact-us"
                  text="Contact Us"
                />
              </div>
            </div>
            {/* Resource part */}
            <div className="sm:col-span-3">
              <Title title="Resources" />

              <div className="flex flex-col gap-y-2 md:grid md:grid-cols-2 gap-x-6">
                <TextAnchor
                  className="hover:text-orange"
                  href="/awards-and-recognitions"
                  text="Awards & recognitions"
                />
                <TextAnchor
                  className="hover:text-orange"
                  href="/investors"
                  text="Investors"
                />
                <TextAnchor
                  className="hover:text-orange"
                  href="/resources"
                  text="News & articles"
                />
                <TextAnchor
                  className="hover:text-orange"
                  href="/careers"
                  text="Careers"
                />
              </div>
            </div>
            {/* Product part - For phhone */}
            <div className="sm:col-span-6">
              <Title title="Products" />

              <div className="flex flex-col sm:grid sm:grid-cols-2 sm:gap-x-10 gap-y-2 items-start md:hidden ">
                <MobDropdownNav
                  title="Cath Labs family"
                  options={
                    <CathLabRoutes onClose={toggle} isColumnView hideTitle />
                  }
                />
                <MobDropdownNav
                  title="Stents family"
                  options={
                    <StentRoutes onClose={toggle} isColumnView hideTitle />
                  }
                />
                <MobDropdownNav
                  title="Balloon Catheters family"
                  options={
                    <BalloonRoutes onClose={toggle} isColumnView hideTitle />
                  }
                />
                <div className="flex flex-col self-stretch">
                  <div className="sm:hidden">
                    <OtherRoutes onClose={toggle} hideTitle />
                  </div>
                  <div className="sm:block hidden">
                    <OtherRoutes onClose={toggle} isColumnView hideTitle />
                  </div>
                </div>
              </div>

              {/* For desktop */}
              <div className="hidden md:flex gap-x-10 xl:gap-x-16 2xl:gap-x-24">
                <div>
                  <CathLabRoutes onClose={toggle} />
                </div>
                <div>
                  <StentRoutes onClose={toggle} />
                </div>
                <div>
                  <BalloonRoutes onClose={toggle} />
                </div>
                <div>
                  <OtherRoutes onClose={toggle} />
                </div>
              </div>
            </div>
          </section>
        </section>
      </section>
      {/* Social links section */}
      <section className="flex flex-col-reverse gap-y-6 blade-top-padding blade-bottom-padding sm:py-0 sm:grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-12 md:divide-x-1 divide-solid divide-gray border-t-1 border-solid border-gray">
        <img
          src={logoSVG}
          alt="Innvolutions's logo"
          className="h-16 xsl:h-20 mx-auto col-start-1 md:col-start-1 lg:col-end-4 sm:blade-top-margin sm:blade-bottom-margin lg:blade-top-margin-sm lg:blade-bottom-margin-sm xsl:col-start-1 xsl:col-end-5"
        />
        <article className="flex flex-col lg:px-8 xsl:px-10 2xl:px-20 mx-auto w-11/12 lg:w-full lg:flex-row lg:justify-between items-center justify-center gap-y-2 lg:col-start-4 lg:col-end-13 xsl:col-start-5 xsl:col-end-13">
          <SocialProfile classes="gap-x-5 " />
          <div className="">
            <TextAnchor
              className="hover:text-orange"
              text="Privacy policy"
              href="/privacy-policy"
            />{' '}
            /{' '}
            <TextAnchor
              className="hover:text-orange"
              text="Terms & conditions"
              href="/terms-and-conditions"
            />
          </div>
          <div className="text-black text-opacity-80 font-regular text-[14px] lg:text-sm mr-[7%]">
            Copyright © {new Date().getFullYear().toString()} Innvolution
          </div>
        </article>
      </section>
      <ScrollToTopBtn />
    </footer>
  )
}

function Title({ title }: { title: string }) {
  return (
    <span className="mb-2 block border-b-1 border-solid border-gray pb-2 w-full uppercase text-orange font-medium text-base md:text-lg">
      {title}
    </span>
  )
}

function Contact({
  title,
  text,
  linkTo,
}: {
  title: string
  text: string
  linkTo: string
}) {
  return (
    <>
      <Title title={title} />
      <div className="font-regular ">
        <a
          className="text-sm lg:text-base focus-visible:text-orange hover:text-orange hover:underline focus-visible:underline underline-offset-4 transition-colors outline-none"
          href={linkTo}
        >
          {text}
        </a>
      </div>
    </>
  )
}

function MobDropdownNav({
  title,
  options,
}: {
  title: string
  options: JSX.Element
}) {
  return (
    <div className="flex flex-col self-stretch">
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="justify-between pr-2 flex lg:text-base items-center text-left hover:underline font-medium outline-none whitespace-nowrap focus-visible:underline underline-offset-4 decoration-from-font text-opacity-80 transition-colors duration-300 ease-in-out focus-visible:text-opacity-100 hover:text-opacity-100 text-black text-sm">
              {title}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="3"
                stroke="currentColor"
                className={`mt-1 ${
                  open ? 'rotate-180' : ''
                } transition-all duration-300 ease-in-out w-3 h-3 md:h-4 md:w-4`}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </Disclosure.Button>

            <Transition
              show={open}
              enter="transition duration-100 ease-out"
              enterFrom="transform opacity-0"
              enterTo="transform opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform  opacity-100"
              leaveTo="transform  opacity-0 "
            >
              <Disclosure.Panel static className="pt-2 pb-4">
                {options}
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    </div>
  )
}
